import countries from '../components/guess-the-country/data/processedCountryData'
import { vocab } from '../components/personal-finance-vocab/data/data';
import { states } from '../components/guess-the-state/data/stateData'

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
  }
  return array
}

const randomizeCountries = () => {
  const independentCountries = countries.filter((country) => country['independent'])
  return shuffleArray(independentCountries)
}

const randomizeStates = () => {
  return shuffleArray(states)
}

const randomizePersonalVocab = () => {
  return shuffleArray(vocab)
}

const getRandomNumber = (num = 11) => {
  return Math.floor(Math.random() * num)
}

const selectGif = () => {
  const options = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]
  const shuffledOptions = shuffleArray(options)

  return options[Math.floor(Math.random() * shuffledOptions.length)]
}

export {
  getRandomNumber,
  randomizeCountries,
  randomizePersonalVocab,
  randomizeStates,
  selectGif,
  shuffleArray
}
