const money = [
  'https://media1.tenor.com/m/KjtgHR9aTFkAAAAC/donald-duck-counting-money.gif',
  'https://media1.tenor.com/m/I2XRNzzq0TAAAAAd/cat-salary.gif',
  'https://media1.tenor.com/m/x495e0SNwe8AAAAd/the-bread-cat.gif',
  'https://media1.tenor.com/m/xuDoIguqUo0AAAAC/backing-you-get-yours.gif',
  'https://media1.tenor.com/m/5Z-o3OKSPFIAAAAC/adult-swim-monkey.gif',
  'https://media1.tenor.com/m/UeOID8bIvYIAAAAC/rabbit-bunny.gif',
  'https://media1.tenor.com/m/xoCluhHYqlsAAAAd/mastrix.gif',
  'https://media1.tenor.com/m/xdKP9X5rVOQAAAAC/kitten.gif',
  'https://media1.tenor.com/m/F7A_EAN0XoIAAAAC/funny.gif',
  'https://media1.tenor.com/m/cNNmZnG3Os4AAAAd/the-bread-cat.gif',
]

export default money
