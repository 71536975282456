/* eslint-disable */
import { Helmet } from 'react-helmet'
import { useEffect, useRef, useState } from 'react'
import Score from '../shared-components/Score'
import Nav from '../shared-components/Nav'
import { Button, Card, CardHeader, CardBody, CardFooter, Flex, Link, Heading, Text, RadioGroup, Radio, Stack } from '@chakra-ui/react'
import { facts } from './data/data'
import getBorderCSS from '../shared-components/clueBorders'
import Confetti from 'react-confetti'
import { ExternalLinkIcon } from '@chakra-ui/icons'

const WomensHistoryMain = () => {
  const [score, setScore] = useState(0)
  const [problemsAttempted, setProblemsAttempted] = useState(0)
  const [guess, setGuess] = useState('')
  const [isCorrect, setIsCorrect] = useState(false)
  const [attempted, setAttempted] = useState(false)
  const [currentQuestion, setCurrentQuestion] = useState(1)
  const [level, setLevel] = useState(1)
  const [showConfetti, setShowConfetti] = useState(true)
  const [borderCSS, setBorderCSS] = useState({ border: 'white solid 2px' })
  const QUESTIONS_PER_LEVEL = 5
  const TOTAL_QUESTIONS = 20

  const blinkingStars = <span className="Blink-me">⭐🌟⭐</span>
  const windowWidth = useRef(window.innerWidth)
  const windowHeight = useRef(window.innerHeight)

  useEffect(() => {
    if (problemsAttempted > 0 &&
      problemsAttempted % QUESTIONS_PER_LEVEL === 0) {
      setShowConfetti(true)
      if (level < 4) setLevel(level + 1)
    } else {
      setShowConfetti(false)
    }
  }, [problemsAttempted])

  useEffect(() => {
    if (currentQuestion > 1) {
      setShowConfetti(false)
    }
  }, [currentQuestion])

  useEffect(() => {
    if (level > 1) {
      const styling = getBorderCSS(level)
      setBorderCSS(styling)
    }
  }, [level])

  const handleGuess = () => {
    if (guess === facts[currentQuestion - 1].answer) {
      setIsCorrect(true)
      setScore(score + 1)
      setAttempted(false)
    } else {
      setIsCorrect(false)
      setAttempted(true)
    }

    setProblemsAttempted(problemsAttempted + 1)
  }

  const handleNext = () => {
    setCurrentQuestion(currentQuestion + 1)
    setIsCorrect(false)
    setGuess('')
    setAttempted(false)
  }

  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Women's History Game</title>
        <meta name="description" content="A quiz-style game about women's history." />
      </Helmet>

      <>
        <Nav name="Women's History" />

        {currentQuestion <= TOTAL_QUESTIONS ?
          <Score
            level={level}
            score={score}
            amountGuessed={problemsAttempted}
            outline={borderCSS['border']?.split(' ')[0] || null}
            totalLevels={4}
          /> : null}

        {(attempted || isCorrect) &&
          problemsAttempted % QUESTIONS_PER_LEVEL === 0 &&
          currentQuestion < TOTAL_QUESTIONS ?
          <p className="Blink-me" style={{ marginBottom: '10px' }}>Next level reached! Starting level {level}.</p>
          : null}

        {showConfetti ?
          <Confetti
            width={windowWidth.current}
            height={windowHeight.current}
          /> : null}

        {currentQuestion > TOTAL_QUESTIONS ?
          <div className="Congrats-container" style={{ marginTop: '20px' }}>
            <p className="Congrats-message">{blinkingStars} Congratulations! Great job! {blinkingStars}</p>
            <p className="Congrats-message">
              You got <span style={{ fontWeight: 900, color: '#6374ed' }}>{score} out of {TOTAL_QUESTIONS}</span> questions about women's history right.
            </p>
            <p className="Congrats-message" style={{ marginTop: '10px' }}>Thanks for playing!</p>
            <div style={{ marginTop: '20px' }}>
              <img alt="Celebrating women's history" src="https://media.giphy.com/media/v1.Y2lkPTc5MGI3NjExbjVpc3kwd29waDRqZjlsNTRycDNvZXoxaDFvc2h1Y2UzZGl2dHMyNSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/XOOxMUuEMgU7NaQZ24/giphy.gif" width="320" height="270" />
            </div>
          </div> : null}

        <Flex flexDirection="row" justifyContent="center" flexWrap="wrap">
          {currentQuestion <= TOTAL_QUESTIONS ?
            <Card key={1} maxW='md'>
              <CardHeader>
                <Heading size="md">{facts[currentQuestion - 1].question}</Heading>
              </CardHeader>
              <CardBody pt={0}>
                <Flex flex='1' justify='center'>
                  <RadioGroup onChange={guess => setGuess(guess)} value={guess}>
                    <Stack direction='column'>
                      {facts[currentQuestion - 1].options.map((option, index) => {
                        return (<Radio key={index} value={option} isDisabled={((isCorrect || attempted) && option !== facts[currentQuestion - 1].answer)}>
                          <span style={{ color: (isCorrect && option === facts[currentQuestion - 1].answer) ? 'green' : 'black' }}>{option}</span>
                        </Radio>)
                      })}
                    </Stack>
                    {(!isCorrect && !attempted) ? <Button mt={2} onClick={() => handleGuess()} isDisabled={guess === ''}>Submit</Button> : null}
                    {(isCorrect || attempted) ? <Button mt={2} onClick={() => handleNext()}>Next</Button> : null}
                  </RadioGroup>
                </Flex>
              </CardBody>
              <CardFooter pt={0}>
                {isCorrect || attempted ?
                  <Flex flexDirection='column' alignItems='center'>
                    {isCorrect ? <Text color="green" mb={4} as="b">{blinkingStars} Correct! {blinkingStars}</Text> : null}
                    {attempted ? <Text color="red" mb={4} as="b">The correct answer is {facts[currentQuestion - 1].answer}.</Text> : null}
                    <Text>{facts[currentQuestion - 1].explanation}</Text>
                    <Link color="green" href={facts[currentQuestion - 1].link} isExternal>
                      <span style={{ fontWeight: 800 }}>Learn more. </span><ExternalLinkIcon />
                    </Link>
                  </Flex> : null}
              </CardFooter>
            </Card> : null}
        </Flex>
      </>
    </div>
  )
}

export default WomensHistoryMain
