const vocab = [
  {
    question: <p>A type of retirement savings plan offered by many employers in the United States. <br />It allows employees to save and invest a portion of their paycheck before taxes are taken out.</p>,
    answer: "401(k)",
    options: ["Pension", "401(k)", "Annuity", "IRA"],
  },
  {
    question: "A type of investment account where a financial advisor or investment professional provides ongoing investment advice and management services.",
    answer: "Advisory account",
    options: ["Money Market Account (MMA)", "Exchange-Traded Funds (ETF)", "Advisory account", "Brokerage account"]
  },
  {
    question: "A financial product that provides a steady stream of income, typically used as a way to generate income during retirement.",
    answer: "Annuity",
    options: ["Pension", "401(k)", "Annuity", "IRA"],
  },
  {
    question: "Loans made by investors to companies or governments. In return, the borrower promises to pay back the loan amount (principal) on a set date and makes regular interest payments (coupons) until then.",
    answer: "Bonds",
    options: ["Bonds", "Certificates of Deposit (CD)", "Money Market Account (MMA)", "IRA"],
  },
  {
    question: "A broker facilitates the buying and selling of securities on your behalf. The relationship is typically non-discretionary.",
    answer: "Brokerage account",
    options: ["Money Market Account (MMA)", "Exchange-Traded Funds (ETF)", "Advisory account", "Brokerage account"],
  },
  {
    question: <p>A type of savings account offered by banks and credit unions that pays a fixed interest rate for a specified period of time.<br /> Common terms include 6 months, 1 year, 2 years, and 5 years.</p>,
    answer: "Certificate of Deposit (CD)",
    options: ["Bonds", "Certificate of Deposit (CD)", "Money Market Account (MMA)", "IRA"],
  },
  {
    question: "A type of permanent life insurance that provides both a death benefit and a savings component, e.g. whole life insurance.",
    answer: "Cash value life insurance",
    options: ["Term life insurance", "Universal life insurance", "Variable life insurance", "Cash value life insurance"],
  },
  {
    question: <p>A financial arrangement where a third party holds and regulates the payment of funds or documents between two other parties involved in a transaction.<br /> The third party, known as the escrow agent, ensures that both parties meet the agreed-upon conditions before releasing the funds or assets.</p>,
    answer: "Escrow",
    options: ["Escrow", "Pension", "Bond", "Title"],
  },
  {
    question: "Investment funds traded on stock exchanges, similar to mutual funds but with more liquidity.",
    answer: "Exchange-Traded Funds (ETF)",
    options: ["Money Market Account (MMA)", "Exchange-Traded Funds (ETF)", "Advisory account", "Brokerage account"]
  },
  {
    question: "Tax-advantaged accounts used to pay for qualified medical expenses.",
    answer: "Health Savings Accounts (HSAs)",
    options: ["Health Savings Accounts (HSAs)", "Money Market Account (MMA)", "Exchange-Traded Funds (ETF)", "High yield savings account (HYSA)"],
  },
  {
    question: "The difference between the value of your property and how much you owe on your home loan.",
    answer: "Home equity",
    options: ["Home Equity Line of Credit (HELOC)", "Title", "Home equity", "Escrow"],
  },
  {
    question: "A type of savings account that offers a higher interest rate compared to traditional savings accounts. Typically offered by online banks, they are a low-risk option for saving money, often with no or low fees.",
    answer: "High yield savings account (HYSA)",
    options: ["Health Savings Accounts (HSAs)", "Money Market Account (MMA)", "Exchange-Traded Funds (ETF)", "High yield savings account (HYSA)"],
  },
  {
    question: "A type of retirement savings account that offers tax advantages to help individuals save for retirement. Unlike 401(k)s, which are typically sponsored by employers, these are established by individuals.",
    answer: "IRA",
    options: ["Pension", "401(k)", "Annuity", "IRA"],
  },
  {
    question: "A type of savings account offered by banks and credit unions that typically pays higher interest rates than a regular savings account.",
    answer: "Money Market Account (MMA)",
    options: ["Money Market Account (MMA)", "Exchange-Traded Funds (ETF)", "Advisory account", "Brokerage account"],
  },
  {
    question: "Pooled investment funds that invest in a diversified portfolio of stocks, bonds, or other securities.",
    answer: "Mutual funds",
    options: ["Money Market Account (MMA)", "Exchange-Traded Funds (ETF)", "Mutual funds", "Cryptocurrency"],
  },
  {
    question: <p>The interest rate that commercial banks charge their most creditworthy and financially stable customers, typically large corporations.<br /> It serves as a benchmark for various types of loans and credit.</p>,
    answer: "Prime rate",
    options: ["Greed index", "Prime rate", "Fear index", "Sub-prime rate"]
  },
  {
    question: "A type of retirement plan that provides a fixed, regular income to an employee after they retire for the rest of their life.",
    answer: "Pension",
    options: ["Pension", "401(k)", "Annuity", "IRA"],
  },
  {
    question: "Modeled after mutual funds, they offer shares in commercial real estate portfolios, which include properties like office buildings, shopping malls, apartments, hotels, and more.",
    answer: "REIT (Real Estate Investment Trust)",
    options: ["Private Equity Real Estate Funds", "Mortgage-Backed Securities", "Real Estate Exchange-Traded Funds", "REIT (Real Estate Investment Trust)"]
  },
  {
    question: <p>A stock market index that measures the performance of 500 of the largest publicly traded companies in the United States.<br /> It is widely regarded as one of the most representative benchmarks of the U.S. stock market and is used as a gauge of the overall health of the economy.</p>,
    answer: "S&P 500",
    options: ["Dow Jones Industrial Average", "Nasdaq", "S&P 500", "Russell 2000"]
  },
  {
    question: 'A type of life insurance policy that provides coverage for a specified period, or "term," typically ranging from 10 to 30 years.',
    answer: "Term life insurance",
    options: ["Term life insurance", "Universal life insurance", "Variable life insurance", "Cash value life insurance"]
  },
]

export { vocab }
