const states = [
  {
    name: "Alabama",
    abbreviation: "AL",
    capital: "Montgomery"
  },
  {
    name: "Alaska",
    abbreviation: "AK",
    capital: "Juneau"
  },
  {
    name: "Arizona",
    abbreviation: "AZ",
    capital: "Phoenix"
  },
  {
    name: "Arkansas",
    abbreviation: "AR",
    capital: "Little Rock"
  },
  {
    name: "California",
    abbreviation: "CA",
    capital: "Sacramento"
  },
  {
    name: "Colorado",
    abbreviation: "CO",
    capital: "Denver"
  },
  {
    name: "Connecticut",
    abbreviation: "CT",
    capital: "Hartford"
  },
  {
    name: "Delaware",
    abbreviation: "DE",
    capital: "Dover"
  },
  {
    name: "Florida",
    abbreviation: "FL",
    capital: "Tallahassee"
  },
  {
    name: "Georgia",
    abbreviation: "GA",
    capital: "Atlanta"
  },
  {
    name: "Hawaii",
    abbreviation: "HI",
    capital: "Honolulu"
  },
  {
    name: "Idaho",
    abbreviation: "ID",
    capital: "Boise"
  },
  {
    name: "Illinois",
    abbreviation: "IL",
    capital: "Springfield"
  },
  {
    name: "Indiana",
    abbreviation: "IN",
    capital: "Indianapolis"
  },
  {
    name: "Iowa",
    abbreviation: "IA",
    capital: "Des Moines"
  },
  {
    name: "Kansas",
    abbreviation: "KS",
    capital: "Topeka"
  },
  {
    name: "Kentucky",
    abbreviation: "KY",
    capital: "Frankfort"
  },
  {
    name: "Louisiana",
    abbreviation: "LA",
    capital: "Baton Rouge"
  },
  {
    name: "Maine",
    abbreviation: "ME",
    capital: "Augusta"
  },
  {
    name: "Maryland",
    abbreviation: "MD",
    capital: "Annapolis"
  },
  {
    name: "Massachusetts",
    abbreviation: "MA",
    capital: "Boston"
  },
  {
    name: "Michigan",
    abbreviation: "MI",
    capital: "Lansing"
  },
  {
    name: "Minnesota",
    abbreviation: "MN",
    capital: "Saint Paul"
  },
  {
    name: "Mississippi",
    abbreviation: "MS",
    capital: "Jackson"
  },
  {
    name: "Missouri",
    abbreviation: "MO",
    capital: "Jefferson City"
  },
  {
    name: "Montana",
    abbreviation: "MT",
    capital: "Helena"
  },
  {
    name: "Nebraska",
    abbreviation: "NE",
    capital: "Lincoln"
  },
  {
    name: "Nevada",
    abbreviation: "NV",
    capital: "Carson City"
  },
  {
    name: "New Hampshire",
    abbreviation: "NH",
    capital: "Concord"
  },
  {
    name: "New Jersey",
    abbreviation: "NJ",
    capital: "Trenton"
  },
  {
    name: "New Mexico",
    abbreviation: "NM",
    capital: "Santa Fe"
  },
  {
    name: "New York",
    abbreviation: "NY",
    capital: "Albany"
  },
  {
    name: "North Carolina",
    abbreviation: "NC",
    capital: "Raleigh"
  },
  {
    name: "North Dakota",
    abbreviation: "ND",
    capital: "Bismarck"
  },
  {
    name: "Ohio",
    abbreviation: "OH",
    capital: "Columbus"
  },
  {
    name: "Oklahoma",
    abbreviation: "OK",
    capital: "Oklahoma City"
  },
  {
    name: "Oregon",
    abbreviation: "OR",
    capital: "Salem"
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA",
    capital: "Harrisburg"
  },
  {
    name: "Rhode Island",
    abbreviation: "RI",
    capital: "Providence"
  },
  {
    name: "South Carolina",
    abbreviation: "SC",
    capital: "Columbia"
  },
  {
    name: "South Dakota",
    abbreviation: "SD",
    capital: "Pierre"
  },
  {
    name: "Tennessee",
    abbreviation: "TN",
    capital: "Nashville"
  },
  {
    name: "Texas",
    abbreviation: "TX",
    capital: "Austin"
  },
  {
    name: "Utah",
    abbreviation: "UT",
    capital: "Salt Lake City"
  },
  {
    name: "Vermont",
    abbreviation: "VT",
    capital: "Montpelier"
  },
  {
    name: "Virginia",
    abbreviation: "VA",
    capital: "Richmond"
  },
  {
    name: "Washington",
    abbreviation: "WA",
    capital: "Olympia"
  },
  {
    name: "West Virginia",
    abbreviation: "WV",
    capital: "Charleston"
  },
  {
    name: "Wisconsin",
    abbreviation: "WI",
    capital: "Madison"
  },
  {
    name: "Wyoming",
    abbreviation: "WY",
    capital: "Cheyenne"
  }
]

const territories = [
  {
    name: "District Of Columbia",
    abbreviation: "DC"
  },
  {
    name: "American Samoa",
    abbreviation: "AS"
  },
  {
    name: "Federated States Of Micronesia",
    abbreviation: "FM"
  },
  {
    name: "Guam",
    abbreviation: "GU"
  },
  {
    name: "Marshall Islands",
    abbreviation: "MH"
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP"
  },
  {
    name: "Palau",
    abbreviation: "PW"
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR"
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI"
  },
]

const stateBorderOutlines = {
  'alabama': 'https://gisgeography.com/wp-content/uploads/2020/04/Alabama-Outline-Map.jpg',
  'alaska': 'https://gisgeography.com/wp-content/uploads/2020/04/Alaska-Outline-Map.jpg',
  'arizona': 'https://gisgeography.com/wp-content/uploads/2020/04/Arizona-Outline-Map.jpg',
  'arkansas': 'https://gisgeography.com/wp-content/uploads/2020/04/Arkansas-Outline-Map.jpg',
  'california': 'https://gisgeography.com/wp-content/uploads/2020/04/California-Outline-Map.jpg',
  'colorado': 'https://gisgeography.com/wp-content/uploads/2020/04/Colorado-Outline-Map.jpg',
  'connecticut': 'https://gisgeography.com/wp-content/uploads/2020/04/Connecticut-Outline-Map.jpg',
  'delaware': 'https://gisgeography.com/wp-content/uploads/2020/04/Delaware-Outline-Map.jpg',
  'florida': 'https://gisgeography.com/wp-content/uploads/2020/04/Florida-Outline-Map.jpg',
  'georgia': 'https://gisgeography.com/wp-content/uploads/2020/04/Georgia-Outline-Map.jpg',
  'hawaii': 'https://gisgeography.com/wp-content/uploads/2020/04/Hawaii-Outline-Map.jpg',
  'idaho': 'https://gisgeography.com/wp-content/uploads/2020/03/Idaho-Outline-Map.jpg',
  'illinois': 'https://gisgeography.com/wp-content/uploads/2020/03/Illinois-Outline-Map.jpg',
  'indiana': 'https://gisgeography.com/wp-content/uploads/2020/03/Indiana-Outline-Map.jpg',
  'iowa': 'https://gisgeography.com/wp-content/uploads/2020/03/Iowa-Outline-Map.jpg',
  'kansas': 'https://gisgeography.com/wp-content/uploads/2020/03/Kansas-Outline-Map.jpg',
  'kentucky': 'https://gisgeography.com/wp-content/uploads/2020/03/Kentucky-Outline-Map.jpg',
  'louisiana': 'https://gisgeography.com/wp-content/uploads/2020/03/Louisiana-Outline-Map.jpg',
  'maine': 'https://gisgeography.com/wp-content/uploads/2020/03/Maine-Outline-Map.jpg',
  'maryland': 'https://gisgeography.com/wp-content/uploads/2020/03/Maryland-Outline-Map.jpg',
  'massachusetts': 'https://gisgeography.com/wp-content/uploads/2020/03/Massachusetts-Outline-Map.jpg',
  'michigan': 'https://gisgeography.com/wp-content/uploads/2020/03/Michigan-Outline-Map.jpg',
  'minnesota': 'https://gisgeography.com/wp-content/uploads/2020/03/Minnesota-Outline-Map.jpg',
  'mississippi': 'https://gisgeography.com/wp-content/uploads/2020/03/Mississippi-Outline-Map.jpg',
  'missouri': 'https://gisgeography.com/wp-content/uploads/2020/03/Missouri-Outline-Map.jpg',
  'montana': 'https://gisgeography.com/wp-content/uploads/2020/03/Montana-Outline-Map.jpg',
  'nebraska': 'https://gisgeography.com/wp-content/uploads/2020/03/Nebraska-Outline-Map.jpg',
  'nevada': 'https://gisgeography.com/wp-content/uploads/2020/03/Nevada-Outline-Map.jpg',
  'new hampshire': 'https://gisgeography.com/wp-content/uploads/2020/03/New-Hampshire-Outline-Map.jpg',
  'new jersey': 'https://gisgeography.com/wp-content/uploads/2020/03/New-Jersey-Outline-Map.jpg',
  'new mexico': 'https://gisgeography.com/wp-content/uploads/2020/03/New-Mexico-Outline-Map.jpg',
  'new york': 'https://gisgeography.com/wp-content/uploads/2020/03/New-York-Outline-Map.jpg',
  'north carolina': 'https://gisgeography.com/wp-content/uploads/2020/03/North-Carolina-Outline-Map.jpg',
  'north dakota': 'https://gisgeography.com/wp-content/uploads/2020/03/North-Dakota-Outline-Map.jpg',
  'ohio': 'https://gisgeography.com/wp-content/uploads/2020/03/Ohio-Outline-Map.jpg',
  'oklahoma': 'https://gisgeography.com/wp-content/uploads/2020/03/Oklahoma-Outline-Map.jpg',
  'oregon': 'https://gisgeography.com/wp-content/uploads/2020/03/Oregon-Outline-Map.jpg',
  'pennsylvania': 'https://gisgeography.com/wp-content/uploads/2020/03/Pennsylvania-Outline-Map.jpg',
  'rhode island': 'https://gisgeography.com/wp-content/uploads/2020/03/Rhode-Island-Outline-Map.jpg',
  'south carolina': 'https://gisgeography.com/wp-content/uploads/2020/03/South-Carolina-Outline-Map.jpg',
  'south dakota': 'https://gisgeography.com/wp-content/uploads/2020/03/South-Dakota-Outline-Map.jpg',
  'tennessee': 'https://gisgeography.com/wp-content/uploads/2020/03/Tennessee-Outline-Map.jpg',
  'texas': 'https://gisgeography.com/wp-content/uploads/2020/03/Texas-Outline-Map.jpg',
  'utah': 'https://gisgeography.com/wp-content/uploads/2020/03/Utah-Outline-Map.jpg',
  'vermont': 'https://gisgeography.com/wp-content/uploads/2020/03/Vermont-Outline-Map.jpg',
  'virginia': 'https://gisgeography.com/wp-content/uploads/2020/03/Virginia-Outline-Map.jpg',
  'washington': 'https://gisgeography.com/wp-content/uploads/2020/03/Washington-Outline-Map.jpg',
  'west virginia': 'https://gisgeography.com/wp-content/uploads/2020/03/West-Virginia-Outline-Map.jpg',
  'wisconsin': 'https://gisgeography.com/wp-content/uploads/2020/03/Wisconsin-Outline-Map.jpg',
  'wyoming': 'https://gisgeography.com/wp-content/uploads/2020/03/Wyoming-Outline-Map.jpg'
}

export { stateBorderOutlines, states, territories } ;
