import { Flex, Heading } from '@chakra-ui/react'
import { stateBorderOutlines } from './data/stateData'
import './State.css'

function Clues(props) {
  const { borderCSS, stateData, hints } = props

  return (
    <div className="Clue-container">
      <div className="Clue-content" style={{ ...borderCSS, textAlign: 'center' }}>
        <Heading as="h2" size="lg" mt={0}>The capital is {stateData?.capital}.</Heading>

        {hints > 0 ?
          <>
            <Flex justify="center" mt={4}>
              <img
                className="State-outline"
                src={stateBorderOutlines[stateData.name.toLowerCase()]}
                alt="Map outline of state"
              />
            </Flex>
            <div className="State-outline-caption">State outline</div>
          </>
          : null}
      </div>
    </div>
  )
}

export default Clues
